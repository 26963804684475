// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-drafts-nutrition-research-js": () => import("./../../../src/pages/drafts/nutrition-research.js" /* webpackChunkName: "component---src-pages-drafts-nutrition-research-js" */),
  "component---src-pages-drafts-pricing-js": () => import("./../../../src/pages/drafts/pricing.js" /* webpackChunkName: "component---src-pages-drafts-pricing-js" */),
  "component---src-pages-drafts-visual-search-js": () => import("./../../../src/pages/drafts/visual-search.js" /* webpackChunkName: "component---src-pages-drafts-visual-search-js" */),
  "component---src-pages-food-logging-sdk-js": () => import("./../../../src/pages/food-logging-sdk.js" /* webpackChunkName: "component---src-pages-food-logging-sdk-js" */),
  "component---src-pages-food-recognition-js": () => import("./../../../src/pages/food-recognition.js" /* webpackChunkName: "component---src-pages-food-recognition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-legal-docs-js": () => import("./../../../src/templates/LegalDocs.js" /* webpackChunkName: "component---src-templates-legal-docs-js" */)
}

